<template>
  <main>
    <div class="content_wrapper">
      <div class="content">
        <div class="mentions" />
        <div class="status-page">
          <!-- <Form
            id="form"
            :form_id="status_form_id"
            :uid="uid"
            :submission_id="submission_id"
            :server="server"
            v-on:results="getResult"
            v-on:form_submit_loading="loading"
            @on_error="viewError"
            :hideError="hiderror"
          /> -->

         <!--  <div v-if="questionnaire" class="quest_button">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="{2}"
                d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
              /></svg
            ><router-link
              
              :to="`/questionnaire/${submission_id}/${uid}`"
              >Répondre à notre questionnaire</router-link
            >
          </div> -->
        </div>
      </div>
    </div>
  </main>
</template>
<script>
var consts = require("../config");
import axios from "axios";

export default {
  name: "Status",
  data: function() {
    return {
      questionnaire: true,
      hiderror: true,
      submission_id: this.$route.params._id,
      status_form_id: "6103ad547da1830015b527ea",
      server: "https://api.lucien.ai",
      uid: this.$route.params._secu,
    };
  },
  async mounted() {
    let { data } = await axios.get(
      "https://api.lucien.ai/api/v2/submissions/" +
        this.submission_id +
        "?uid=" +
        this.uid
    );

    if (data.question_1) {
      this.questionnaire = false;
    } else {
      this.questionnaire = true;
    }
  },
  methods: {
    loading(value) {
      if (value === true) {
        this.$swal({
          title: "Envoi en cours",
          allowOutsideClick: false,
          html: "Merci de bien vouloir patienter ...",
          timerProgressBar: true,
          onBeforeOpen: () => {
            this.$swal.showLoading();
          },
        });
      }
    },
    getResult: function(result) {
      if (result._id) {
        this.$swal({
          type: "success",
          confirmButtonText: "OK",
          confirmButtonColor: "#C94330",
          html:
            "<b>Votre statut a bien été mis à jour. Vous serez informé de son traitement par email.</b>",
        }).then((result) => {
          window.location.href = "/status/"+this.$route.params._id+"/"+this.uid;
        });
      } else {
        this.$swal({
          type: "error",
          confirmButtonText: "OK",
          confirmButtonColor: "#C94330",
          text: "Une erreur est survenue",
        });
      }
    },
    viewError(err) {
      console.log(err);

      this.$swal({
        confirmButtonText: "OK",
        confirmButtonColor: "#C94330",
        title: "Désolé, une erreur est survenue.",
        text: err,
      }).then(function() {
        window.location.href = "/";
      });
    },
  },
};
</script>
